.contact-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 50%;
    padding: 1em 25% 1em 25%;
    text-align: left;
}
.white-input {
    background-color: white;
    label {
        color: #666;
    }
}

.contact-form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    button {
        width: 100%;
        background-color: #666;
        color: white;
        border-radius: 3px;
        margin: 0;
        padding: .5em 1em;
        border: 1px solid transparent;
        font-size: 1em;
    }
}

@media (max-width: 600px) {
    .contact-wrapper {
        width: calc(100% - 2em);
        padding: 1em;
    }
}
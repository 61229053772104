@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
@import './mixins.scss';

@mixin noSelect($cursor) {
    -ms-touch-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    cursor: $cursor;
}

.navigation {
    display: flex;
    justify-content: space-between;
    //align-items: center;
    flex-wrap: wrap;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    color: #666;
    background-color: #fafafa;
    border-bottom: #ccc solid 2px;

    h1 {
        @include noSelect(default);
        text-transform: uppercase;
        letter-spacing: -1px;
        margin: .25em;
        color: #333;

        a {
            color: inherit;
            text-decoration: none;
        }
    }

    .menu-items, .social {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .menu-items {
        flex-grow: 1;
        li {
            margin: 1em;

            a {
                text-decoration: none;
                transition: .2s color linear;
                color: inherit;

                &:hover {
                    color: red;
                }
            }
        }
    }

    ul {
        li {
            a {
                @include noSelect(pointer);
                margin: .2em;
                text-decoration: none;
                color: #666;
                transition: .3s color linear;
            }
        }
    }
}

$social: (
    twitter: (unicode: "\f081", hover-color: #55acee),
    linkedin: (unicode: "\f08c", hover-color: #007bb5),
    instagram: (unicode: "\f16d", hover-color: #125688)
);

@each $icon, $name in $social {
    .#{$icon}:hover {
        color: map-get($name, hover-color);
    }

    .#{$icon}::before {
        content: map-get($name, unicode);
    }
}

.social {
    font-family: fontawesome;
    font-size: 2em;
}

@media (max-width: 480px) {
    .navigation {
        .social {
            display: none;
        }
    }
}

@media (max-width: 453px) {
    .navigation {
        justify-content: center;
        .menu-items {
            flex-direction: column;
            li {
                margin: .5em;
            }
        }
    }
}

@media (min-width: 906px) {
    .footer-social {
        display: none;
    }
}

@media (max-width: 905px) {
    .navigation {
        .social {
            display: none;
        }
    }
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.footer-social {
  background-color: white;
  display: flex;
  justify-content: space-between;
  
  p {
    margin: auto 0;
    padding-left: 1em;
  }

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      padding: .2em;
      a {
        transition: .3s color linear;
        text-decoration: none;
        color: #666;
      }
    }
  }
}

a span {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}
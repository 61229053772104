.gallery-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.gallery-container .gallery-item {
  margin: 1em;
  -webkit-transition: .3s opacity ease-in-out;
  transition: .3s opacity ease-in-out;
  height: 100%;
  position: relative;
}

.gallery-container .gallery-item img {
  max-width: 440px;
  width: 100%;
}

.gallery-container .gallery-item .player-button {
  display: inline-block;
  position: absolute;
  left: 50%;
  color: white;
  font-family: fontawesome;
  font-size: 4em;
  max-width: 440px;
  left: calc(50% - .5em);
  top: calc(50% - .5em);
}

.gallery-container .gallery-item:hover {
  cursor: pointer;
  opacity: 0.5;
}

._16-9 {
  padding: 56.25% 0 0 0;
}

._1-1 {
  padding: 100% 0 0 0;
}

._4-3 {
  padding-top: 75%;
}

._3-2 {
  padding-top: 66.66%;
}

._8-5 {
  padding-top: 62.5%;
}

._2-39-1 {
  padding: 42.18% 0 0 0;
}

@media (max-width: 440px) {
  .gallery-container .gallery-item .player-button {
    font-size: 2em;
  }
}

.container-profile {

    max-width: 800px;
    margin: 0 auto;

    a {
        color: inherit;
        font-weight: 700;
        text-decoration: none;
    }

    header {
        padding: 1em 0 1em 0;
    }

    section {
        padding: 2em 12% 6em 12%;
        text-align: left;
        line-height: 1.4;

        p {
            margin: 0;
        }
    }
}

.company-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;


    img {

        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
        max-width: 100px;
        max-height: 100px;
        margin: .5em;
        transition: .2s ease-in-out transform;
        background-color: #ccc;
    }

    img:hover {
        transform: scale(1.2, 1.2)
    }
}
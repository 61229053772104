@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
.navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  color: #666;
  background-color: #fafafa;
  border-bottom: #ccc solid 2px;
}

.navigation h1 {
  -ms-touch-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  text-transform: uppercase;
  letter-spacing: -1px;
  margin: .25em;
  color: #333;
}

.navigation h1 a {
  color: inherit;
  text-decoration: none;
}

.navigation .menu-items, .navigation .social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation .menu-items {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.navigation .menu-items li {
  margin: 1em;
}

.navigation .menu-items li a {
  text-decoration: none;
  -webkit-transition: .2s color linear;
  transition: .2s color linear;
  color: inherit;
}

.navigation .menu-items li a:hover {
  color: red;
}

.navigation ul li a {
  -ms-touch-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  margin: .2em;
  text-decoration: none;
  color: #666;
  -webkit-transition: .3s color linear;
  transition: .3s color linear;
}

.twitter:hover {
  color: #55acee;
}

.twitter::before {
  content: "";
}

.linkedin:hover {
  color: #007bb5;
}

.linkedin::before {
  content: "";
}

.instagram:hover {
  color: #125688;
}

.instagram::before {
  content: "";
}

.social {
  font-family: fontawesome;
  font-size: 2em;
}

@media (max-width: 480px) {
  .navigation .social {
    display: none;
  }
}

@media (max-width: 453px) {
  .navigation {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .navigation .menu-items {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .navigation .menu-items li {
    margin: .5em;
  }
}

@media (min-width: 906px) {
  .footer-social {
    display: none;
  }
}

@media (max-width: 905px) {
  .navigation .social {
    display: none;
  }
}
